.table-striped-rows tr:nth-child(2n) td {
  background-color: #eee;
}
.table-striped-rows thead {
  background-color: #fafafa;
}
.ant-table-wrapper {
  overflow: auto;
  background-color: #fff;
}
.ant-table-content {
  min-height: 12vh;
}
.ant-table-tbody > tr > td {
  padding: 10px;
}
