.flex_row{
    display: flex;
    flex-direction: row;
}


/* TABLE */

.table{
    width: 80%;
    margin: 0 auto;
}

.top_bar, .info_bar{
    display: flex;
    justify-content: space-around;
    padding: 1em;    
    border-bottom: 1px solid #ddd;
}

.top_bar{
    background: #fff;
    font-weight: 700;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.spinner{
    margin: 5em 50%;
}

.info_bar{
    background: #f0f2f5
}

.top_bar_items, .info_bar_items{
    width: 10%;
}

.info_bar:hover{
    background: #cccccc;
}

.info_bar_button{
    cursor: pointer;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 40px;
}

/* POPUP */

.popup_screen{
    z-index: 10;
    width: 500px;
    height: 500px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px 2px rgba(148,148,148,1);

}

.popup_screen_top_bar{
    align-content: center;
    background: #eeeeee;
    height: 40px;
    cursor: grab;
}

.popup_screen_top_bar>button{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20;
    height: 40px;
    width: 40px;
    border: none;
    cursor: pointer;
    outline: none;
}

.popup_screen_top_bar>button:hover{
    background: red;
}
.popup_screen_fields{
    padding: 2em;
    height: 100%;
}
.popup_screen_textarea{
   height: 60%; 
   width: 100%;
   resize: none;
   outline: none;
}

.popup_screen_button{
    float: right;
}

#popup_screen_quot_nr{
    width: 100%;
    margin: 0 auto;
    font-weight: 700;
    font-size: 150%;
    width: 20%;
    height: 40px;
}

#confirmer-label {
    color: #7BBC1F;
}

#canceller-label {
    color: #f5222d;
}

.statusContainer{
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height:100px;
}

.stepbar{

    overflow-x: scroll;
    padding: 20px 0!important;
}

.companyContainer,
.modalContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.modalContainer > div {
    flex: 0 0 50%;
    padding: 10px;
}

.infoBox{
    flex: 0 0 50%;
    padding: 10px;
}

.company{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  .button{
      border-radius: 999px;
  }
  .ant-modal-header{
    border-radius: 15px 15px 0 0 ;
}

  .ant-modal-content{
      border-radius:  15px ;
  }

  .ant-modal-footer{
      padding: 2em;
  }

  .rv-discrete-color-legend-item__color {
      height: 18px;
  }

  .dashboard-panel {
      background-color: white;
      padding: 32px;
      border-radius: 16px;
  }